<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      dataurl:"/vehicle-property",
      baseroute:"vehicle-property",
      title:"Kelengkapan Kendaraan",
      fields : [
        { key: 'name', label: 'Nama Kelengkapan', type: 'input', rules:'required'},
        { key: 'is_check', label: 'Pengecekan', type: 'bool'},
      ]
    }
  },
}
</script>